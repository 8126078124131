import { Formik, Form, Field as FField } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { api } from "../../api";
import {
  Image,
  Body1Strong,
  Button,
  Textarea,
  Combobox,
  Option,
} from "@fluentui/react-components";
import COMBOBOX from "../../Component/Combobox";
import TEXT from "../../Component/Text";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Loader from "../../Component/Loader";
import UPLOADIMAGE from "../../Component/ImageUploader";
import { toast } from "react-toastify";
import DROPDOWNWITHIMAGECONTAINER from "../../Component/AvatarCombo";
import * as Yup from "yup";
import axios from "axios";
import "./index.css";

interface AvatarGenerator2Props {
  slug?: string;
  owner?: string;
  lessonID?: string;
  language?: string;
  host?: string;
  gpu?: string;
  database?: string;
}

interface SelectedLessonType {
  lessonId: string;
  lessonName: string;
}

const AvatarGenerator2: React.FC<AvatarGenerator2Props> = ({
  slug,
  owner,
  lessonID,
  language: propLanguage,
  host,
  gpu,
  database,
}) => {
  const [language, setLanguage] = useState<string | undefined>(propLanguage);

  const audioName = useRef<string>("");
  const combineImageName = useRef<string>("");
  const slectedLanguage = useRef<string>(propLanguage || "");

  const [avatarArray, setAvatarArray] = useState<any[]>([]);
  const [lessions, setLessions] = useState<any[]>([]);
  const [selectedLesson, setSelectedLesson] =
    useState<SelectedLessonType | null>(null);
  const [backgroundArray, setBackgroundArray] = useState<any[]>([]);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [vedioSrc, setVedioSrc] = useState<string>("");
  const [audioSrc, setAudioSrc] = useState<string>("");
  const [showVedio, setShowVedio] = useState<boolean>(false);

  const [showAudio, setShowAudio] = useState<boolean>(false);
  const [showImg, setShowImage] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const avatarImage = useRef<string>("");
  const backgroundImage = useRef<string>("");
  const [avatarSelected, setAvatarSelected] = useState<boolean>(false);
  const [bgSelected, setBgSelected] = useState<boolean>(false);

  var languageOption = [
    "English",
    "Spanish",
    "French",
    "German",
    "Japanese",
    "Korean",
    "Chinese",
    "Russian",
    "Turkish",
    "Hindi",
    "Portuguese",
  ];

  const sassToken =
    "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D";

  var genderOption = ["Male", "Female"];
  // var avatarOption = [
  //   {
  //     Id: 1,
  //     image_name: "man.png",
  //     imgPath:
  //       "https://avatargansstg.blob.core.windows.net/avatar/man.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
  //   },
  //   { Id: 2, image_name: "Choose Another", imgPath: "" },
  // ];
  var avatarOption = [
    {
      Id: 1,
      image_name: "avatar1.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 2,
      image_name: "avatar2.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar2.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 3,
      image_name: "avatar3.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar3.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 4,
      image_name: "avatar4.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar4.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 5,
      image_name: "avatar11.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar11.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 6,
      image_name: "avatar12.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar12.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 7,
      image_name: "avatar13.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar13.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 8,
      image_name: "avatar14.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar14.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 9,
      image_name: "avatar15.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar15.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 10,
      image_name: "avatar16.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar16.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 11,
      image_name: "avatar17.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar17.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 12,
      image_name: "avatar18.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar18.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    // {
    //   Id: 13,
    //   image_name: "einstein_1.png",
    //   imgPath:
    //     "https://avatargansstg.blob.core.windows.net/avatar/einstein_1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    // },
    // {
    //   Id: 14,
    //   image_name: "woman_2.png",
    //   imgPath:
    //     "https://avatargansstg.blob.core.windows.net/avatar/woman_2.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    // },
    { Id: 15, image_name: "Choose Another", imgPath: "" },
  ];
  // var backGoundOption = [
  //   {
  //     Id: 1,
  //     image_name: "bg1.png",
  //     imgPath:
  //       "https://avatargansstg.blob.core.windows.net/background-images/bg1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
  //   },
  //   { Id: 2, image_name: "Choose Another", imgPath: "" },
  // ];
  var backGoundOption = [
    {
      Id: 1,
      image_name: "bg1.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 2,
      image_name: "bg2.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg2.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 3,
      image_name: "bg3.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg3.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 4,
      image_name: "bg4.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg4.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 5,
      image_name: "bg5.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg5.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 6,
      image_name: "bg6.jpeg",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg6.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    { Id: 7, image_name: "Choose Another", imgPath: "" },
  ];

  const handleAvatarValue = (avatar: any) => {
    if (typeof avatar == "string") {
      avatarImage.current = avatar;
      setAvatarSelected(true);
    } else {
      const formData = new FormData();
      formData.append("avatar_images", avatar);

      api
        .post("imageavatar/", formData)
        .then((res) => {
          avatarImage.current = res.data.avatar_name;
          setAvatarSelected(true);
        })
        .catch((ex) => {
          console.log(ex);
          toast.error("Choose Another Avatar", {
            autoClose: 1500,
          });
        });
    }
  };
  const handleBackValue = (background: any) => {
    if (typeof background == "string") {
      backgroundImage.current = background;
      setBgSelected(true);
    } else {
      const formData = new FormData();
      formData.append("background_image", background);

      api
        .post("bgimage/", formData)
        .then((res) => {
          backgroundImage.current = res.data.filename;
          setBgSelected(true);
        })
        .catch((ex) => {
          console.log(ex);
          toast.error("Choose Another Background Image", {
            autoClose: 1500,
          });
        });
    }

    //
  };

  const storeAvatarVideo = (videoUrl: string) => {
    let data: {
      lessonId?: string;
      lessonName?: string;
      videoUrl: string;
      userMail?: string;
    } = {
      videoUrl,
    };
    let baseUrl = "";
    if (database) {
      baseUrl =
        database === "prod"
          ? "https://api.acv.app"
          : "https://acv-app-api-preprod.azurewebsites.net";
    }
    if (selectedLesson) {
      data["lessonId"] = selectedLesson.lessonId;
      data["lessonName"] = selectedLesson.lessonName;
    }
    if (owner) {
      data["userMail"] = owner;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/avatar-videos?slug=${slug}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    if (slug && database && baseUrl) {
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getAvatar = () => {
    api.get("/getimageavatar/").then((res) => {
      setAvatarArray(res.data);
      console.log(avatarArray);
    });
  };
  const getBackground = () => {
    api.get("getbgimage/").then((res) => {
      setBackgroundArray(res.data);

      console.log(backgroundArray);
    });
  };
  const getFinalImage = () => {
    if (avatarImage.current && backgroundImage.current) {
      const values = {
        avatar_image: avatarImage.current,
        background_image: backgroundImage.current,
      };
      // avatar_image background_image

      api
        .post("finalcombinedimage/", values)
        .then((res) => {
          setShowImage(true);
          setImageSrc(res.data.image_url);
          combineImageName.current = res.data.combined_image;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Cannot Combined the Image", {
            autoClose: 1500,
          });
        });
    } else {
      setShowError(true);
    }
  };
  const vedioGeneration = () => {
    const filename = `${slug || "noslug"}_${owner || "noowner"}_${
      lessonID || "nolesson"
    }_${slectedLanguage.current || "english"}.wav`;

    const values = {
      combined_image_name: combineImageName.current,
      audio_file_name: audioName.current,
      filename_slug: slug || "noslug",
      filename_lang: slectedLanguage.current,
    };

    setOpenModal(true);
    setShowVedio(false);
    api
      .post("postonlyclick/", values)
      .then((res) => {
        setOpenModal(false);
        setShowVedio(true);
        if (!!res?.data?.final_result_video) {
          slug && storeAvatarVideo(res.data.final_result_video);
          setVedioSrc(res.data.final_result_video);
        } else {
          toast.error("Video is not generated!", {
            autoClose: 1500,
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
        setOpenModal(false);
        toast.error("Video is not generated!", {
          autoClose: 1500,
        });
      });
  };
  const getAllLessons = () => {
    const accessToken = localStorage.getItem("accessToken");
    let baseUrl = "";
    if (database) {
      baseUrl =
        database === "prod"
          ? "https://api.acv.app"
          : "https://acvappapi-preprod.acv.app";
    }
    if (baseUrl && accessToken) {
      axios
        .post(
          `${baseUrl}/api/lession/get-lession-by-teacher?slug=${slug}`,
          {
            LessonDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
          },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        )
        .then((res) => {
          const lessions = res.data.result;
          const formattedLessions = lessions.map(
            (lesson: { Title: string; ClassName: string }) => {
              return { header: lesson.ClassName, value: lesson.Title };
            }
          );
          setLessions(formattedLessions);
          if (lessonID) {
            const findedLesson: { Title: string; ClassName: string } =
              lessions.find(
                (lesson: { Title: string }) => lesson.Title === lessonID
              );
            setSelectedLesson({
              lessonId: findedLesson.Title,
              lessonName: findedLesson.ClassName,
            });
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const initialVal = {
    prompt: "",
    language: propLanguage || "",
    gender: "",
  };

  const validationSchema = Yup.object().shape({
    prompt: Yup.string().required("Please enter a prompt; it cannot be empty."),
    language: Yup.string().required("Please select a language."),
  });

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getAllLessons();
    }
  }, [localStorage.getItem("accessToken")]);

  return (
    <>
      <div>
        <TEXT headingValue="Avatar Generator With Animation" />

        <DROPDOWNWITHIMAGECONTAINER
          headingValue="Choose Your Avatar"
          handleAvatarValue={handleAvatarValue}
          optionValue={avatarOption}
          type="avatar"
        />
        {showError && !avatarImage.current && (
          <p style={{ color: "red", fontSize: "14px" }}>
            Please select your avatar.
          </p>
        )}

        <DROPDOWNWITHIMAGECONTAINER
          headingValue="Choose Your Background"
          handleAvatarValue={handleBackValue}
          optionValue={backGoundOption}
          type="background"
        />
        {showError && !backgroundImage.current && (
          <p style={{ color: "red", fontSize: "14px" }}>
            Please select your background.
          </p>
        )}
        {/* <Button
          disabled={!bgSelected || !avatarSelected}
          className="preview-button"
          onClick={getFinalImage}
        >
          Preview
        </Button> */}

        {showImg ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "Center",
              justifyContent: "center",
              width: "72%",
            }}
          >
            <Image src={imageSrc} height={400} width={400} />
          </div>
        ) : (
          ""
        )}
        <br />
        <Formik
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));

            if (avatarImage.current && backgroundImage.current) {
              const payload = {
                solution: slug ? "ACV" : "AVATAR_GEN",
                tenant: slug || "",
                userEmail: owner || "",
                avatarImage: avatarImage?.current?.split("?")[0],
                backgroundImage: backgroundImage?.current?.split("?")[0],
                userPrompt: values.prompt,
                selectedLanguage: values.language,
                selectedGender: values.gender,
                selectedVoice: "Default",
                lessonId: selectedLesson?.lessonId || "",
                lessonName: selectedLesson?.lessonName || "",
              };
              setOpenModal(true);

              const baseUrl =
                database === "prod"
                  ? "https://api.acv.app"
                  : "https://acvappapi-preprod.acv.app";

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${baseUrl}/api/avatar-request`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify(payload),
              };

              axios
                .request(config)
                .then((response) => {
                  setOpenModal(false);
                  resetForm();
                  setAvatarSelected(false);
                  setBgSelected(false);
                  avatarImage.current = "";
                  backgroundImage.current = "";
                  setImageSrc("");

                  toast.success(
                    "Avatar request successfully submitted, once your avatar is generated you will receive an email notification. In case your request is for a lesson in Assignments, it will be automatically linked.",
                    {
                      autoClose: false,
                      closeOnClick: true,
                      position: "top-right", // Mantiene el toast en la esquina superior derecha
                      style: {
                        width: "300px", // Ajusta el ancho para que sea más visible
                        whiteSpace: "pre-line", // Permite saltos de línea automáticos
                      },
                    }
                  );
                })
                .catch((error) => {
                  setOpenModal(false);
                  toast.error("Avatar Request submission failed");
                  console.log(error);
                });

              slectedLanguage.current = values.language;
            } else {
              setShowError(true);
            }
            // api
            //   .post("postaudiogen/", values)
            //   .then((res) => {
            //     setOpenModal(false);
            //     setShowAudio(true);
            //     setAudioSrc(res.data?.audio_url);
            //     audioName.current = res.data?.audio_filename;
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     setOpenModal(false);
            //     toast.error("Audio is not generated!", {
            //       autoClose: 1500,
            //     });
            //   });

            // }, 500);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Body1Strong>Enter Your Prompt Here</Body1Strong>

                <Textarea
                  resize="none"
                  style={{
                    background: "#e5e5e5",
                    width: "72.5%",
                    marginTop: "3%",
                  }}
                  placeholder="Enter speech"
                  value={values["prompt"]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="prompt"
                />
                {errors.prompt && touched.prompt && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.prompt}
                  </p>
                )}
              </div>

              {lessonID && (
                <Body1Strong
                  style={{
                    display: "block",
                    marginTop: "2%",
                    marginBottom: "2%",
                    fontSize: "1.5rem",
                  }}
                >
                  Choose Lesson
                </Body1Strong>
              )}

              {lessonID && (
                <Combobox
                  style={{ background: "#e5e5e5", width: "72.5%" }}
                  disabled={lessions?.length === 0}
                  // aria-labelledby={comboId}
                  placeholder={"Select lesson"}
                  onOptionSelect={(event, data) => {
                    console.log("data0", data);
                    setSelectedLesson({
                      lessonId: data.optionValue || "",
                      lessonName: data.optionText || "",
                    });
                  }}
                  id={"lesson-select"}
                  value={selectedLesson?.lessonName || ""} // Usamos el valor aquí
                >
                  {lessions.map((lesson) => (
                    <Option key={lesson.header} value={lesson.value}>
                      {lesson.header}
                    </Option>
                  ))}
                </Combobox>
              )}

              <COMBOBOX
                headingValue="Choose Language"
                SelectionValue={"Select language for voice"}
                value={values["language"]}
                optionValue={languageOption}
                setValue={setFieldValue}
                name={"language"}
              />
              {errors.language && touched.language && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {errors.language}
                </p>
              )}
              <COMBOBOX
                headingValue="Choose Gender Voice"
                SelectionValue={"Select Gender Voice"}
                value={values["gender"]}
                optionValue={genderOption}
                setValue={setFieldValue}
                name={"gender"}
              />
              {/* <Button
                className="generate-voice-button"
                style={{
                  marginTop: "7px",
                  background: "#585A96",
                  color: "White",
                }}
                type="submit"
                disabled={
                  !imageSrc || !values["language"] || !values["prompt"].trim()
                }
              >
                {" "}
                Generate Voice
              </Button>
              <Button
                className="generate-video-button"
                style={{
                  marginTop: "7px",
                  background: "#585A96",
                  color: "White",
                }}
                onClick={vedioGeneration}
                disabled={!imageSrc || !audioSrc}
              >
                {" "}
                Generate Video
              </Button> */}
              <Button
                className="submit-request-button"
                style={{
                  marginTop: "7px",
                  background: "#585A96",
                  color: "White",
                }}
                type="submit"
                disabled={false}
              >
                {" "}
                Submit Request
              </Button>
            </Form>
          )}
        </Formik>
        {showAudio ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "Center",
              justifyContent: "center",
              width: "72%",
            }}
          >
            <audio controls>
              <source src={audioSrc} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : (
          ""
        )}
        {showVedio ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "Center",
              justifyContent: "center",
              width: "72%",
            }}
          >
            <video width="400" height="350" preload="true" autoPlay controls>
              <source src={vedioSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          ""
        )}
        {openModal ? (
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            closeOnOverlayClick={false}
            center
          >
            <Loader
              size="large"
              labelPosition="below"
              labelValue="Generating..."
            />
          </Modal>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AvatarGenerator2;
